// Generated from antlr/SolidityBlocks.g4 by ANTLR 4.9.0-SNAPSHOT


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { FailedPredicateException } from "antlr4ts/FailedPredicateException";
import { NotNull } from "antlr4ts/Decorators";
import { NoViableAltException } from "antlr4ts/NoViableAltException";
import { Override } from "antlr4ts/Decorators";
import { Parser } from "antlr4ts/Parser";
import { ParserRuleContext } from "antlr4ts/ParserRuleContext";
import { ParserATNSimulator } from "antlr4ts/atn/ParserATNSimulator";
import { ParseTreeListener } from "antlr4ts/tree/ParseTreeListener";
import { ParseTreeVisitor } from "antlr4ts/tree/ParseTreeVisitor";
import { RecognitionException } from "antlr4ts/RecognitionException";
import { RuleContext } from "antlr4ts/RuleContext";
//import { RuleVersion } from "antlr4ts/RuleVersion";
import { TerminalNode } from "antlr4ts/tree/TerminalNode";
import { Token } from "antlr4ts/Token";
import { TokenStream } from "antlr4ts/TokenStream";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";

import { SolidityBlocksListener } from "./SolidityBlocksListener";

export class SolidityBlocksParser extends Parser {
	public static readonly T__0 = 1;
	public static readonly T__1 = 2;
	public static readonly T__2 = 3;
	public static readonly T__3 = 4;
	public static readonly T__4 = 5;
	public static readonly Constructorkeyword = 6;
	public static readonly Identifier = 7;
	public static readonly WS = 8;
	public static readonly COMMENT = 9;
	public static readonly STRINGS = 10;
	public static readonly STRINGS_2 = 11;
	public static readonly PRAGMA = 12;
	public static readonly IMPORT = 13;
	public static readonly LINE_COMMENT = 14;
	public static readonly AMP = 15;
	public static readonly TILDE = 16;
	public static readonly PIPE = 17;
	public static readonly LT = 18;
	public static readonly GT = 19;
	public static readonly DOT = 20;
	public static readonly EQUAL = 21;
	public static readonly LPAREN = 22;
	public static readonly RBRACE = 23;
	public static readonly LBRACE = 24;
	public static readonly RPAREN = 25;
	public static readonly PLUS = 26;
	public static readonly MINUS = 27;
	public static readonly STAR = 28;
	public static readonly SLASH = 29;
	public static readonly BACKSLASH = 30;
	public static readonly PERCENT = 31;
	public static readonly CARET = 32;
	public static readonly BANG = 33;
	public static readonly QUESTION = 34;
	public static readonly COLON = 35;
	public static readonly SEMI = 36;
	public static readonly COMMA = 37;
	public static readonly OTHER = 38;
	public static readonly RULE_sol = 0;
	public static readonly RULE_contract = 1;
	public static readonly RULE_function = 2;
	public static readonly RULE_functionDescriptor = 3;
	public static readonly RULE_subrule = 4;
	public static readonly RULE_functionname = 5;
	// tslint:disable:no-trailing-whitespace
	public static readonly ruleNames: string[] = [
		"sol", "contract", "function", "functionDescriptor", "subrule", "functionname",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'abstract'", "'contract'", "'interface'", "'library'", "'function'", 
		"'constructor'", undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, "'&'", "'~'", "'|'", "'<'", "'>'", "'.'", 
		"'='", "'('", "'}'", "'{'", "')'", "'+'", "'-'", "'*'", "'/'", "'\\'", 
		"'%'", "'^'", "'!'", "'?'", "':'", "';'", "','",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, undefined, undefined, undefined, undefined, undefined, "Constructorkeyword", 
		"Identifier", "WS", "COMMENT", "STRINGS", "STRINGS_2", "PRAGMA", "IMPORT", 
		"LINE_COMMENT", "AMP", "TILDE", "PIPE", "LT", "GT", "DOT", "EQUAL", "LPAREN", 
		"RBRACE", "LBRACE", "RPAREN", "PLUS", "MINUS", "STAR", "SLASH", "BACKSLASH", 
		"PERCENT", "CARET", "BANG", "QUESTION", "COLON", "SEMI", "COMMA", "OTHER",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(SolidityBlocksParser._LITERAL_NAMES, SolidityBlocksParser._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return SolidityBlocksParser.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace

	// @Override
	public get grammarFileName(): string { return "SolidityBlocks.g4"; }

	// @Override
	public get ruleNames(): string[] { return SolidityBlocksParser.ruleNames; }

	// @Override
	public get serializedATN(): string { return SolidityBlocksParser._serializedATN; }

	protected createFailedPredicateException(predicate?: string, message?: string): FailedPredicateException {
		return new FailedPredicateException(this, predicate, message);
	}

	constructor(input: TokenStream) {
		super(input);
		this._interp = new ParserATNSimulator(SolidityBlocksParser._ATN, this);
	}
	// @RuleVersion(0)
	public sol(): SolContext {
		let _localctx: SolContext = new SolContext(this._ctx, this.state);
		this.enterRule(_localctx, 0, SolidityBlocksParser.RULE_sol);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 15;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while ((((_la) & ~0x1F) === 0 && ((1 << _la) & ((1 << SolidityBlocksParser.T__0) | (1 << SolidityBlocksParser.T__1) | (1 << SolidityBlocksParser.T__2) | (1 << SolidityBlocksParser.T__3))) !== 0)) {
				{
				{
				this.state = 12;
				this.contract();
				}
				}
				this.state = 17;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 18;
			this.match(SolidityBlocksParser.EOF);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public contract(): ContractContext {
		let _localctx: ContractContext = new ContractContext(this._ctx, this.state);
		this.enterRule(_localctx, 2, SolidityBlocksParser.RULE_contract);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 21;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la === SolidityBlocksParser.T__0) {
				{
				this.state = 20;
				this.match(SolidityBlocksParser.T__0);
				}
			}

			this.state = 23;
			_la = this._input.LA(1);
			if (!((((_la) & ~0x1F) === 0 && ((1 << _la) & ((1 << SolidityBlocksParser.T__1) | (1 << SolidityBlocksParser.T__2) | (1 << SolidityBlocksParser.T__3))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			this.state = 24;
			this.functionname();
			this.state = 25;
			this.match(SolidityBlocksParser.LBRACE);
			this.state = 30;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 3, this._ctx);
			while (_alt !== 1 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1 + 1) {
					{
					this.state = 28;
					this._errHandler.sync(this);
					switch (this._input.LA(1)) {
					case SolidityBlocksParser.T__4:
					case SolidityBlocksParser.Constructorkeyword:
						{
						this.state = 26;
						this.function();
						}
						break;
					case SolidityBlocksParser.Identifier:
					case SolidityBlocksParser.AMP:
					case SolidityBlocksParser.TILDE:
					case SolidityBlocksParser.PIPE:
					case SolidityBlocksParser.LT:
					case SolidityBlocksParser.GT:
					case SolidityBlocksParser.DOT:
					case SolidityBlocksParser.EQUAL:
					case SolidityBlocksParser.LPAREN:
					case SolidityBlocksParser.RBRACE:
					case SolidityBlocksParser.LBRACE:
					case SolidityBlocksParser.RPAREN:
					case SolidityBlocksParser.PLUS:
					case SolidityBlocksParser.MINUS:
					case SolidityBlocksParser.STAR:
					case SolidityBlocksParser.SLASH:
					case SolidityBlocksParser.BACKSLASH:
					case SolidityBlocksParser.PERCENT:
					case SolidityBlocksParser.CARET:
					case SolidityBlocksParser.BANG:
					case SolidityBlocksParser.QUESTION:
					case SolidityBlocksParser.COLON:
					case SolidityBlocksParser.SEMI:
					case SolidityBlocksParser.COMMA:
						{
						this.state = 27;
						this.subrule();
						}
						break;
					default:
						throw new NoViableAltException(this);
					}
					}
				}
				this.state = 32;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 3, this._ctx);
			}
			this.state = 33;
			this.match(SolidityBlocksParser.RBRACE);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public function(): FunctionContext {
		let _localctx: FunctionContext = new FunctionContext(this._ctx, this.state);
		this.enterRule(_localctx, 4, SolidityBlocksParser.RULE_function);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 35;
			this.functionDescriptor();
			this.state = 36;
			this.match(SolidityBlocksParser.LPAREN);
			this.state = 40;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === SolidityBlocksParser.Identifier) {
				{
				{
				this.state = 37;
				this.match(SolidityBlocksParser.Identifier);
				}
				}
				this.state = 42;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 51;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 6, this._ctx);
			while (_alt !== 1 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1 + 1) {
					{
					{
					this.state = 43;
					this.match(SolidityBlocksParser.COMMA);
					this.state = 45;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
					do {
						{
						{
						this.state = 44;
						this.match(SolidityBlocksParser.Identifier);
						}
						}
						this.state = 47;
						this._errHandler.sync(this);
						_la = this._input.LA(1);
					} while (_la === SolidityBlocksParser.Identifier);
					}
					}
				}
				this.state = 53;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 6, this._ctx);
			}
			this.state = 54;
			this.match(SolidityBlocksParser.RPAREN);
			this.state = 58;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (((((_la - 7)) & ~0x1F) === 0 && ((1 << (_la - 7)) & ((1 << (SolidityBlocksParser.Identifier - 7)) | (1 << (SolidityBlocksParser.LPAREN - 7)) | (1 << (SolidityBlocksParser.RPAREN - 7)) | (1 << (SolidityBlocksParser.COMMA - 7)))) !== 0)) {
				{
				{
				this.state = 55;
				_la = this._input.LA(1);
				if (!(((((_la - 7)) & ~0x1F) === 0 && ((1 << (_la - 7)) & ((1 << (SolidityBlocksParser.Identifier - 7)) | (1 << (SolidityBlocksParser.LPAREN - 7)) | (1 << (SolidityBlocksParser.RPAREN - 7)) | (1 << (SolidityBlocksParser.COMMA - 7)))) !== 0))) {
				this._errHandler.recoverInline(this);
				} else {
					if (this._input.LA(1) === Token.EOF) {
						this.matchedEOF = true;
					}

					this._errHandler.reportMatch(this);
					this.consume();
				}
				}
				}
				this.state = 60;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 61;
			this.match(SolidityBlocksParser.LBRACE);
			this.state = 65;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 8, this._ctx);
			while (_alt !== 1 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1 + 1) {
					{
					{
					this.state = 62;
					this.subrule();
					}
					}
				}
				this.state = 67;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 8, this._ctx);
			}
			this.state = 68;
			this.match(SolidityBlocksParser.RBRACE);
			this.state = 70;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 9, this._ctx) ) {
			case 1:
				{
				this.state = 69;
				this.match(SolidityBlocksParser.SEMI);
				}
				break;
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public functionDescriptor(): FunctionDescriptorContext {
		let _localctx: FunctionDescriptorContext = new FunctionDescriptorContext(this._ctx, this.state);
		this.enterRule(_localctx, 6, SolidityBlocksParser.RULE_functionDescriptor);
		try {
			this.state = 75;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case SolidityBlocksParser.T__4:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 72;
				this.match(SolidityBlocksParser.T__4);
				this.state = 73;
				this.functionname();
				}
				break;
			case SolidityBlocksParser.Constructorkeyword:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 74;
				this.match(SolidityBlocksParser.Constructorkeyword);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public subrule(): SubruleContext {
		let _localctx: SubruleContext = new SubruleContext(this._ctx, this.state);
		this.enterRule(_localctx, 8, SolidityBlocksParser.RULE_subrule);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 77;
			_la = this._input.LA(1);
			if (!(((((_la - 7)) & ~0x1F) === 0 && ((1 << (_la - 7)) & ((1 << (SolidityBlocksParser.Identifier - 7)) | (1 << (SolidityBlocksParser.AMP - 7)) | (1 << (SolidityBlocksParser.TILDE - 7)) | (1 << (SolidityBlocksParser.PIPE - 7)) | (1 << (SolidityBlocksParser.LT - 7)) | (1 << (SolidityBlocksParser.GT - 7)) | (1 << (SolidityBlocksParser.DOT - 7)) | (1 << (SolidityBlocksParser.EQUAL - 7)) | (1 << (SolidityBlocksParser.LPAREN - 7)) | (1 << (SolidityBlocksParser.RBRACE - 7)) | (1 << (SolidityBlocksParser.LBRACE - 7)) | (1 << (SolidityBlocksParser.RPAREN - 7)) | (1 << (SolidityBlocksParser.PLUS - 7)) | (1 << (SolidityBlocksParser.MINUS - 7)) | (1 << (SolidityBlocksParser.STAR - 7)) | (1 << (SolidityBlocksParser.SLASH - 7)) | (1 << (SolidityBlocksParser.BACKSLASH - 7)) | (1 << (SolidityBlocksParser.PERCENT - 7)) | (1 << (SolidityBlocksParser.CARET - 7)) | (1 << (SolidityBlocksParser.BANG - 7)) | (1 << (SolidityBlocksParser.QUESTION - 7)) | (1 << (SolidityBlocksParser.COLON - 7)) | (1 << (SolidityBlocksParser.SEMI - 7)) | (1 << (SolidityBlocksParser.COMMA - 7)))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public functionname(): FunctionnameContext {
		let _localctx: FunctionnameContext = new FunctionnameContext(this._ctx, this.state);
		this.enterRule(_localctx, 10, SolidityBlocksParser.RULE_functionname);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 79;
			this.match(SolidityBlocksParser.Identifier);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x03(T\x04\x02\t\x02" +
		"\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04\x07\t\x07" +
		"\x03\x02\x07\x02\x10\n\x02\f\x02\x0E\x02\x13\v\x02\x03\x02\x03\x02\x03" +
		"\x03\x05\x03\x18\n\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x07\x03" +
		"\x1F\n\x03\f\x03\x0E\x03\"\v\x03\x03\x03\x03\x03\x03\x04\x03\x04\x03\x04" +
		"\x07\x04)\n\x04\f\x04\x0E\x04,\v\x04\x03\x04\x03\x04\x06\x040\n\x04\r" +
		"\x04\x0E\x041\x07\x044\n\x04\f\x04\x0E\x047\v\x04\x03\x04\x03\x04\x07" +
		"\x04;\n\x04\f\x04\x0E\x04>\v\x04\x03\x04\x03\x04\x07\x04B\n\x04\f\x04" +
		"\x0E\x04E\v\x04\x03\x04\x03\x04\x05\x04I\n\x04\x03\x05\x03\x05\x03\x05" +
		"\x05\x05N\n\x05\x03\x06\x03\x06\x03\x07\x03\x07\x03\x07\x05 5C\x02\x02" +
		"\b\x02\x02\x04\x02\x06\x02\b\x02\n\x02\f\x02\x02\x05\x03\x02\x04\x06\x06" +
		"\x02\t\t\x18\x18\x1B\x1B\'\'\x04\x02\t\t\x11\'\x02X\x02\x11\x03\x02\x02" +
		"\x02\x04\x17\x03\x02\x02\x02\x06%\x03\x02\x02\x02\bM\x03\x02\x02\x02\n" +
		"O\x03\x02\x02\x02\fQ\x03\x02\x02\x02\x0E\x10\x05\x04\x03\x02\x0F\x0E\x03" +
		"\x02\x02\x02\x10\x13\x03\x02\x02\x02\x11\x0F\x03\x02\x02\x02\x11\x12\x03" +
		"\x02\x02\x02\x12\x14\x03\x02\x02\x02\x13\x11\x03\x02\x02\x02\x14\x15\x07" +
		"\x02\x02\x03\x15\x03\x03\x02\x02\x02\x16\x18\x07\x03\x02\x02\x17\x16\x03" +
		"\x02\x02\x02\x17\x18\x03\x02\x02\x02\x18\x19\x03\x02\x02\x02\x19\x1A\t" +
		"\x02\x02\x02\x1A\x1B\x05\f\x07\x02\x1B \x07\x1A\x02\x02\x1C\x1F\x05\x06" +
		"\x04\x02\x1D\x1F\x05\n\x06\x02\x1E\x1C\x03\x02\x02\x02\x1E\x1D\x03\x02" +
		"\x02\x02\x1F\"\x03\x02\x02\x02 !\x03\x02\x02\x02 \x1E\x03\x02\x02\x02" +
		"!#\x03\x02\x02\x02\" \x03\x02\x02\x02#$\x07\x19\x02\x02$\x05\x03\x02\x02" +
		"\x02%&\x05\b\x05\x02&*\x07\x18\x02\x02\')\x07\t\x02\x02(\'\x03\x02\x02" +
		"\x02),\x03\x02\x02\x02*(\x03\x02\x02\x02*+\x03\x02\x02\x02+5\x03\x02\x02" +
		"\x02,*\x03\x02\x02\x02-/\x07\'\x02\x02.0\x07\t\x02\x02/.\x03\x02\x02\x02" +
		"01\x03\x02\x02\x021/\x03\x02\x02\x0212\x03\x02\x02\x0224\x03\x02\x02\x02" +
		"3-\x03\x02\x02\x0247\x03\x02\x02\x0256\x03\x02\x02\x0253\x03\x02\x02\x02" +
		"68\x03\x02\x02\x0275\x03\x02\x02\x028<\x07\x1B\x02\x029;\t\x03\x02\x02" +
		":9\x03\x02\x02\x02;>\x03\x02\x02\x02<:\x03\x02\x02\x02<=\x03\x02\x02\x02" +
		"=?\x03\x02\x02\x02><\x03\x02\x02\x02?C\x07\x1A\x02\x02@B\x05\n\x06\x02" +
		"A@\x03\x02\x02\x02BE\x03\x02\x02\x02CD\x03\x02\x02\x02CA\x03\x02\x02\x02" +
		"DF\x03\x02\x02\x02EC\x03\x02\x02\x02FH\x07\x19\x02\x02GI\x07&\x02\x02" +
		"HG\x03\x02\x02\x02HI\x03\x02\x02\x02I\x07\x03\x02\x02\x02JK\x07\x07\x02" +
		"\x02KN\x05\f\x07\x02LN\x07\b\x02\x02MJ\x03\x02\x02\x02ML\x03\x02\x02\x02" +
		"N\t\x03\x02\x02\x02OP\t\x04\x02\x02P\v\x03\x02\x02\x02QR\x07\t\x02\x02" +
		"R\r\x03\x02\x02\x02\r\x11\x17\x1E *15<CHM";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!SolidityBlocksParser.__ATN) {
			SolidityBlocksParser.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(SolidityBlocksParser._serializedATN));
		}

		return SolidityBlocksParser.__ATN;
	}

}

export class SolContext extends ParserRuleContext {
	public EOF(): TerminalNode { return this.getToken(SolidityBlocksParser.EOF, 0); }
	public contract(): ContractContext[];
	public contract(i: number): ContractContext;
	public contract(i?: number): ContractContext | ContractContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ContractContext);
		} else {
			return this.getRuleContext(i, ContractContext);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return SolidityBlocksParser.RULE_sol; }
	// @Override
	public enterRule(listener: SolidityBlocksListener): void {
		if (listener.enterSol) {
			listener.enterSol(this);
		}
	}
	// @Override
	public exitRule(listener: SolidityBlocksListener): void {
		if (listener.exitSol) {
			listener.exitSol(this);
		}
	}
}


export class ContractContext extends ParserRuleContext {
	public functionname(): FunctionnameContext {
		return this.getRuleContext(0, FunctionnameContext);
	}
	public LBRACE(): TerminalNode { return this.getToken(SolidityBlocksParser.LBRACE, 0); }
	public RBRACE(): TerminalNode { return this.getToken(SolidityBlocksParser.RBRACE, 0); }
	public function(): FunctionContext[];
	public function(i: number): FunctionContext;
	public function(i?: number): FunctionContext | FunctionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(FunctionContext);
		} else {
			return this.getRuleContext(i, FunctionContext);
		}
	}
	public subrule(): SubruleContext[];
	public subrule(i: number): SubruleContext;
	public subrule(i?: number): SubruleContext | SubruleContext[] {
		if (i === undefined) {
			return this.getRuleContexts(SubruleContext);
		} else {
			return this.getRuleContext(i, SubruleContext);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return SolidityBlocksParser.RULE_contract; }
	// @Override
	public enterRule(listener: SolidityBlocksListener): void {
		if (listener.enterContract) {
			listener.enterContract(this);
		}
	}
	// @Override
	public exitRule(listener: SolidityBlocksListener): void {
		if (listener.exitContract) {
			listener.exitContract(this);
		}
	}
}


export class FunctionContext extends ParserRuleContext {
	public functionDescriptor(): FunctionDescriptorContext {
		return this.getRuleContext(0, FunctionDescriptorContext);
	}
	public LPAREN(): TerminalNode[];
	public LPAREN(i: number): TerminalNode;
	public LPAREN(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(SolidityBlocksParser.LPAREN);
		} else {
			return this.getToken(SolidityBlocksParser.LPAREN, i);
		}
	}
	public RPAREN(): TerminalNode[];
	public RPAREN(i: number): TerminalNode;
	public RPAREN(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(SolidityBlocksParser.RPAREN);
		} else {
			return this.getToken(SolidityBlocksParser.RPAREN, i);
		}
	}
	public LBRACE(): TerminalNode { return this.getToken(SolidityBlocksParser.LBRACE, 0); }
	public RBRACE(): TerminalNode { return this.getToken(SolidityBlocksParser.RBRACE, 0); }
	public Identifier(): TerminalNode[];
	public Identifier(i: number): TerminalNode;
	public Identifier(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(SolidityBlocksParser.Identifier);
		} else {
			return this.getToken(SolidityBlocksParser.Identifier, i);
		}
	}
	public COMMA(): TerminalNode[];
	public COMMA(i: number): TerminalNode;
	public COMMA(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(SolidityBlocksParser.COMMA);
		} else {
			return this.getToken(SolidityBlocksParser.COMMA, i);
		}
	}
	public subrule(): SubruleContext[];
	public subrule(i: number): SubruleContext;
	public subrule(i?: number): SubruleContext | SubruleContext[] {
		if (i === undefined) {
			return this.getRuleContexts(SubruleContext);
		} else {
			return this.getRuleContext(i, SubruleContext);
		}
	}
	public SEMI(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.SEMI, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return SolidityBlocksParser.RULE_function; }
	// @Override
	public enterRule(listener: SolidityBlocksListener): void {
		if (listener.enterFunction) {
			listener.enterFunction(this);
		}
	}
	// @Override
	public exitRule(listener: SolidityBlocksListener): void {
		if (listener.exitFunction) {
			listener.exitFunction(this);
		}
	}
}


export class FunctionDescriptorContext extends ParserRuleContext {
	public functionname(): FunctionnameContext | undefined {
		return this.tryGetRuleContext(0, FunctionnameContext);
	}
	public Constructorkeyword(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.Constructorkeyword, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return SolidityBlocksParser.RULE_functionDescriptor; }
	// @Override
	public enterRule(listener: SolidityBlocksListener): void {
		if (listener.enterFunctionDescriptor) {
			listener.enterFunctionDescriptor(this);
		}
	}
	// @Override
	public exitRule(listener: SolidityBlocksListener): void {
		if (listener.exitFunctionDescriptor) {
			listener.exitFunctionDescriptor(this);
		}
	}
}


export class SubruleContext extends ParserRuleContext {
	public BACKSLASH(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.BACKSLASH, 0); }
	public SLASH(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.SLASH, 0); }
	public STAR(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.STAR, 0); }
	public PLUS(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.PLUS, 0); }
	public MINUS(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.MINUS, 0); }
	public DOT(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.DOT, 0); }
	public COMMA(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.COMMA, 0); }
	public LBRACE(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.LBRACE, 0); }
	public RBRACE(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.RBRACE, 0); }
	public LPAREN(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.LPAREN, 0); }
	public RPAREN(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.RPAREN, 0); }
	public SEMI(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.SEMI, 0); }
	public EQUAL(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.EQUAL, 0); }
	public Identifier(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.Identifier, 0); }
	public QUESTION(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.QUESTION, 0); }
	public LT(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.LT, 0); }
	public GT(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.GT, 0); }
	public BANG(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.BANG, 0); }
	public TILDE(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.TILDE, 0); }
	public AMP(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.AMP, 0); }
	public PIPE(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.PIPE, 0); }
	public CARET(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.CARET, 0); }
	public PERCENT(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.PERCENT, 0); }
	public COLON(): TerminalNode | undefined { return this.tryGetToken(SolidityBlocksParser.COLON, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return SolidityBlocksParser.RULE_subrule; }
	// @Override
	public enterRule(listener: SolidityBlocksListener): void {
		if (listener.enterSubrule) {
			listener.enterSubrule(this);
		}
	}
	// @Override
	public exitRule(listener: SolidityBlocksListener): void {
		if (listener.exitSubrule) {
			listener.exitSubrule(this);
		}
	}
}


export class FunctionnameContext extends ParserRuleContext {
	public Identifier(): TerminalNode { return this.getToken(SolidityBlocksParser.Identifier, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return SolidityBlocksParser.RULE_functionname; }
	// @Override
	public enterRule(listener: SolidityBlocksListener): void {
		if (listener.enterFunctionname) {
			listener.enterFunctionname(this);
		}
	}
	// @Override
	public exitRule(listener: SolidityBlocksListener): void {
		if (listener.exitFunctionname) {
			listener.exitFunctionname(this);
		}
	}
}


