import { ANTLRInputStream, CharStreams, CommonTokenStream } from 'antlr4ts'

import { SolidityLexer } from './antlr/SolidityLexer'
import { SolidityParser } from './antlr/SolidityParser'
import { ASTNode, astNodeTypes, ASTNodeTypeString, ASTVisitor, SourceUnit } from './ast-types'
import { ASTBuilder } from './ASTBuilder'
import ErrorListener from './ErrorListener'
//import { buildTokenList } from './tokens'
import { ParseOptions, Token, TokenizeOptions } from './types'
import util from 'util'
import { ParseTreeListener } from 'antlr4ts/tree/ParseTreeListener'
import { ContractContext, FunctionContext, FunctionDescriptorContext, FunctionnameContext, SolidityBlocksParser } from './antlr/SolidityBlocksParser';
import { SolidityBlocksLexer } from './antlr/SolidityBlocksLexer'
import { SolidityBlocksListener } from './antlr/SolidityBlocksListener'
interface ParserErrorItem {
  message: string
  line: number
  column: number
}

type ParseResult = SourceUnit & {
  errors?: any[]
  tokens?: Token[]
}

export class ParserError extends Error {
  public errors: ParserErrorItem[]

  constructor(args: { errors: ParserErrorItem[] }) {
    super()
    const { message, line, column } = args.errors[0]
    this.message = `${message} (${line}:${column})`
    this.errors = args.errors

    if (Error.captureStackTrace !== undefined) {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = new Error().stack
    }
  }
}/*
export function tokenize(input: string, options: TokenizeOptions = {}): any {
  const inputStream = new ANTLRInputStream(input)
  const lexer = new SolidityLexer(inputStream)

  return buildTokenList(lexer.getAllTokens(), options)
}
*/



export function parse(
  input: string,
  options: ParseOptions = {},
  parseListsener: ParseTreeListener = {}

): ParseResult | null {
  const inputStream = new ANTLRInputStream(input)
  const lexer = new SolidityLexer(inputStream)
  const tokenStream = new CommonTokenStream(lexer)
  const parser = new SolidityParser(tokenStream)

  const listener = new ErrorListener()
  lexer.removeErrorListeners()
  lexer.addErrorListener(listener)

  parser.removeErrorListeners()
  parser.addErrorListener(listener)

  const startTime = Date.now()
  parser.buildParseTree = true
  const sourceUnit = parser.sourceUnit()
  const endTime = Date.now()
  //console.log(`Parsing took ${endTime - startTime}ms`)
  
  const astBuilder = new ASTBuilder(options)

  astBuilder.visit(sourceUnit)

  const ast: ParseResult | null = astBuilder.result as any

  if (ast === null) {
    throw new Error('ast should never be null')
  }

  if (options.tolerant !== true && listener.hasErrors()) {
    throw new ParserError({ errors: listener.getErrors() })
  }
  if (options.tolerant === true && listener.hasErrors()) {
    ast.errors = listener.getErrors()
  }
  return ast
}



export function parseBlock(
  input: string,
  options: ParseOptions = {},
  parseListsener: ParseTreeListener = {}

): ParseResult | null {
  const inputStream = CharStreams.fromString(input)
  const lexer = new SolidityBlocksLexer(inputStream)
  const tokenStream = new CommonTokenStream(lexer)
  const parser = new SolidityBlocksParser(tokenStream)

  const listener = new ErrorListener()
  lexer.removeErrorListeners()
  lexer.addErrorListener(listener)

  parser.removeErrorListeners()
  parser.addErrorListener(listener)

  const result:any = []

  const l:SolidityBlocksListener = {
    exitContract(ctx:ContractContext){
      //console.log(ctx.exception)
      result.push({
        type: 'contract',
        name: ctx.functionname().text,
        start: ctx.start.startIndex,
        end: ctx.stop?.stopIndex,
        startLine: ctx.start.line,
        startColumn: ctx.start.charPositionInLine,
        endLine: ctx.stop?.line,
        endColumn: ctx.stop?.charPositionInLine,
      })
      //console.log(ctx.start.line)
      //console.log(ctx.stop?.line)
    },
    exitFunction(ctx:FunctionContext){

      const parentType = ctx.parent?.constructor.name
      let parentName = ''
      if(parentType === 'ContractContext'){
        if((ctx.parent as ContractContext)?.functionname){
          parentName = (ctx.parent as ContractContext)?.functionname().text
        }
      }
      result.push({
        type: 'function',
        name: ctx.functionDescriptor()?.functionname()?.text 
        || ctx.functionDescriptor()?.Constructorkeyword()?.text
        || null,
        parent: parentName,
        start: ctx.start.startIndex,
        end: ctx.stop?.stopIndex,
        startLine: ctx.start.line,
        startColumn: ctx.start.charPositionInLine,
        endLine: ctx.stop?.line,
        endColumn: ctx.stop?.charPositionInLine,
      })
      //console.log(ctx.start.line)
      //console.log(ctx.stop?.line)
    }
  }

  parser.addParseListener(l)

  const startTime = Date.now()
  parser.buildParseTree = true
  const sourceUnit = parser.sol()
  const endTime = Date.now()
  //console.log(`Parsing blocks took ${endTime - startTime}ms`)
  result.sort((a:any, b:any) => (a.start > b.start) ? 1 : -1)
  return result
}

function _isASTNode(node: unknown): node is ASTNode {
  if (typeof node !== 'object' || node === null) {
    return false
  }

  const nodeAsAny: any = node

  if (Object.prototype.hasOwnProperty.call(nodeAsAny, 'type') && typeof nodeAsAny.type === "string") {
    return astNodeTypes.includes(nodeAsAny.type)
  }

  return false;
}

export function visit(node: unknown, visitor: ASTVisitor, nodeParent?: ASTNode): void {
  if (Array.isArray(node)) {
    node.forEach((child) => visit(child, visitor, nodeParent))
  }

  if (!_isASTNode(node)) return

  let cont = true

  if (visitor[node.type] !== undefined) {
    // TODO can we avoid this `as any`
    cont = visitor[node.type]!(node as any, nodeParent)
  }

  if (cont === false) return

  for (const prop in node) {
    if (Object.prototype.hasOwnProperty.call(node, prop)) {
      // TODO can we avoid this `as any`
      visit((node as any)[prop], visitor, node)
    }
  }

  const selector = (node.type + ':exit') as `${ASTNodeTypeString}:exit`
  if (visitor[selector] !== undefined) {
    // TODO can we avoid this `as any`
    visitor[selector]!(node as any, nodeParent)
  }
}
