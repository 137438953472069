// Generated from antlr/SolidityBlocks.g4 by ANTLR 4.9.0-SNAPSHOT


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { CharStream } from "antlr4ts/CharStream";
import { Lexer } from "antlr4ts/Lexer";
import { LexerATNSimulator } from "antlr4ts/atn/LexerATNSimulator";
import { NotNull } from "antlr4ts/Decorators";
import { Override } from "antlr4ts/Decorators";
import { RuleContext } from "antlr4ts/RuleContext";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";


export class SolidityBlocksLexer extends Lexer {
	public static readonly T__0 = 1;
	public static readonly T__1 = 2;
	public static readonly T__2 = 3;
	public static readonly T__3 = 4;
	public static readonly T__4 = 5;
	public static readonly Constructorkeyword = 6;
	public static readonly Identifier = 7;
	public static readonly WS = 8;
	public static readonly COMMENT = 9;
	public static readonly STRINGS = 10;
	public static readonly STRINGS_2 = 11;
	public static readonly PRAGMA = 12;
	public static readonly IMPORT = 13;
	public static readonly LINE_COMMENT = 14;
	public static readonly AMP = 15;
	public static readonly TILDE = 16;
	public static readonly PIPE = 17;
	public static readonly LT = 18;
	public static readonly GT = 19;
	public static readonly DOT = 20;
	public static readonly EQUAL = 21;
	public static readonly LPAREN = 22;
	public static readonly RBRACE = 23;
	public static readonly LBRACE = 24;
	public static readonly RPAREN = 25;
	public static readonly PLUS = 26;
	public static readonly MINUS = 27;
	public static readonly STAR = 28;
	public static readonly SLASH = 29;
	public static readonly BACKSLASH = 30;
	public static readonly PERCENT = 31;
	public static readonly CARET = 32;
	public static readonly BANG = 33;
	public static readonly QUESTION = 34;
	public static readonly COLON = 35;
	public static readonly SEMI = 36;
	public static readonly COMMA = 37;
	public static readonly OTHER = 38;

	// tslint:disable:no-trailing-whitespace
	public static readonly channelNames: string[] = [
		"DEFAULT_TOKEN_CHANNEL", "HIDDEN",
	];

	// tslint:disable:no-trailing-whitespace
	public static readonly modeNames: string[] = [
		"DEFAULT_MODE",
	];

	public static readonly ruleNames: string[] = [
		"T__0", "T__1", "T__2", "T__3", "T__4", "Constructorkeyword", "Identifier", 
		"WS", "COMMENT", "STRINGS", "STRINGS_2", "PRAGMA", "IMPORT", "LINE_COMMENT", 
		"AMP", "TILDE", "PIPE", "LT", "GT", "DOT", "EQUAL", "LPAREN", "RBRACE", 
		"LBRACE", "RPAREN", "PLUS", "MINUS", "STAR", "SLASH", "BACKSLASH", "PERCENT", 
		"CARET", "BANG", "QUESTION", "COLON", "SEMI", "COMMA", "OTHER",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'abstract'", "'contract'", "'interface'", "'library'", "'function'", 
		"'constructor'", undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, "'&'", "'~'", "'|'", "'<'", "'>'", "'.'", 
		"'='", "'('", "'}'", "'{'", "')'", "'+'", "'-'", "'*'", "'/'", "'\\'", 
		"'%'", "'^'", "'!'", "'?'", "':'", "';'", "','",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, undefined, undefined, undefined, undefined, undefined, "Constructorkeyword", 
		"Identifier", "WS", "COMMENT", "STRINGS", "STRINGS_2", "PRAGMA", "IMPORT", 
		"LINE_COMMENT", "AMP", "TILDE", "PIPE", "LT", "GT", "DOT", "EQUAL", "LPAREN", 
		"RBRACE", "LBRACE", "RPAREN", "PLUS", "MINUS", "STAR", "SLASH", "BACKSLASH", 
		"PERCENT", "CARET", "BANG", "QUESTION", "COLON", "SEMI", "COMMA", "OTHER",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(SolidityBlocksLexer._LITERAL_NAMES, SolidityBlocksLexer._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return SolidityBlocksLexer.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace


	constructor(input: CharStream) {
		super(input);
		this._interp = new LexerATNSimulator(SolidityBlocksLexer._ATN, this);
	}

	// @Override
	public get grammarFileName(): string { return "SolidityBlocks.g4"; }

	// @Override
	public get ruleNames(): string[] { return SolidityBlocksLexer.ruleNames; }

	// @Override
	public get serializedATN(): string { return SolidityBlocksLexer._serializedATN; }

	// @Override
	public get channelNames(): string[] { return SolidityBlocksLexer.channelNames; }

	// @Override
	public get modeNames(): string[] { return SolidityBlocksLexer.modeNames; }

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x02(\u0117\b\x01" +
		"\x04\x02\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06" +
		"\x04\x07\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r" +
		"\t\r\x04\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t" +
		"\x12\x04\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x04\x16\t\x16\x04\x17\t" +
		"\x17\x04\x18\t\x18\x04\x19\t\x19\x04\x1A\t\x1A\x04\x1B\t\x1B\x04\x1C\t" +
		"\x1C\x04\x1D\t\x1D\x04\x1E\t\x1E\x04\x1F\t\x1F\x04 \t \x04!\t!\x04\"\t" +
		"\"\x04#\t#\x04$\t$\x04%\t%\x04&\t&\x04\'\t\'\x03\x02\x03\x02\x03\x02\x03" +
		"\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x03\x03\x03\x03\x03\x03" +
		"\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x03\x04\x03\x04\x03\x04\x03" +
		"\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x05\x03\x05\x03" +
		"\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x05\x03\x06\x03\x06\x03\x06\x03" +
		"\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03\x06\x03\x07\x03\x07\x03\x07\x03" +
		"\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03\x07\x03" +
		"\b\x06\b\x8A\n\b\r\b\x0E\b\x8B\x03\t\x06\t\x8F\n\t\r\t\x0E\t\x90\x03\t" +
		"\x03\t\x03\n\x03\n\x03\n\x03\n\x07\n\x99\n\n\f\n\x0E\n\x9C\v\n\x03\n\x03" +
		"\n\x03\n\x03\n\x03\n\x03\v\x03\v\x07\v\xA5\n\v\f\v\x0E\v\xA8\v\v\x03\v" +
		"\x03\v\x03\v\x03\v\x03\f\x03\f\x07\f\xB0\n\f\f\f\x0E\f\xB3\v\f\x03\f\x03" +
		"\f\x03\f\x03\f\x03\r\x03\r\x03\r\x03\r\x03\r\x03\r\x03\r\x03\r\x07\r\xC1" +
		"\n\r\f\r\x0E\r\xC4\v\r\x03\r\x03\r\x03\r\x03\r\x03\x0E\x03\x0E\x03\x0E" +
		"\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x07\x0E\xD2\n\x0E\f\x0E\x0E\x0E" +
		"\xD5\v\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0F\x03\x0F\x03\x0F\x03" +
		"\x0F\x07\x0F\xDF\n\x0F\f\x0F\x0E\x0F\xE2\v\x0F\x03\x0F\x03\x0F\x03\x10" +
		"\x03\x10\x03\x11\x03\x11\x03\x12\x03\x12\x03\x13\x03\x13\x03\x14\x03\x14" +
		"\x03\x15\x03\x15\x03\x16\x03\x16\x03\x17\x03\x17\x03\x18\x03\x18\x03\x19" +
		"\x03\x19\x03\x1A\x03\x1A\x03\x1B\x03\x1B\x03\x1C\x03\x1C\x03\x1D\x03\x1D" +
		"\x03\x1E\x03\x1E\x03\x1F\x03\x1F\x03 \x03 \x03!\x03!\x03\"\x03\"\x03#" +
		"\x03#\x03$\x03$\x03%\x03%\x03&\x03&\x03\'\x03\'\x03\'\x03\'\x07\x9A\xA6" +
		"\xB1\xC2\xD3\x02\x02(\x03\x02\x03\x05\x02\x04\x07\x02\x05\t\x02\x06\v" +
		"\x02\x07\r\x02\b\x0F\x02\t\x11\x02\n\x13\x02\v\x15\x02\f\x17\x02\r\x19" +
		"\x02\x0E\x1B\x02\x0F\x1D\x02\x10\x1F\x02\x11!\x02\x12#\x02\x13%\x02\x14" +
		"\'\x02\x15)\x02\x16+\x02\x17-\x02\x18/\x02\x191\x02\x1A3\x02\x1B5\x02" +
		"\x1C7\x02\x1D9\x02\x1E;\x02\x1F=\x02 ?\x02!A\x02\"C\x02#E\x02$G\x02%I" +
		"\x02&K\x02\'M\x02(\x03\x02\x05\x06\x022;C\\aac|\x05\x02\v\f\x0F\x0F\"" +
		"\"\x04\x02\f\f\x0F\x0F\x02\u011E\x02\x03\x03\x02\x02\x02\x02\x05\x03\x02" +
		"\x02\x02\x02\x07\x03\x02\x02\x02\x02\t\x03\x02\x02\x02\x02\v\x03\x02\x02" +
		"\x02\x02\r\x03\x02\x02\x02\x02\x0F\x03\x02\x02\x02\x02\x11\x03\x02\x02" +
		"\x02\x02\x13\x03\x02\x02\x02\x02\x15\x03\x02\x02\x02\x02\x17\x03\x02\x02" +
		"\x02\x02\x19\x03\x02\x02\x02\x02\x1B\x03\x02\x02\x02\x02\x1D\x03\x02\x02" +
		"\x02\x02\x1F\x03\x02\x02\x02\x02!\x03\x02\x02\x02\x02#\x03\x02\x02\x02" +
		"\x02%\x03\x02\x02\x02\x02\'\x03\x02\x02\x02\x02)\x03\x02\x02\x02\x02+" +
		"\x03\x02\x02\x02\x02-\x03\x02\x02\x02\x02/\x03\x02\x02\x02\x021\x03\x02" +
		"\x02\x02\x023\x03\x02\x02\x02\x025\x03\x02\x02\x02\x027\x03\x02\x02\x02" +
		"\x029\x03\x02\x02\x02\x02;\x03\x02\x02\x02\x02=\x03\x02\x02\x02\x02?\x03" +
		"\x02\x02\x02\x02A\x03\x02\x02\x02\x02C\x03\x02\x02\x02\x02E\x03\x02\x02" +
		"\x02\x02G\x03\x02\x02\x02\x02I\x03\x02\x02\x02\x02K\x03\x02\x02\x02\x02" +
		"M\x03\x02\x02\x02\x03O\x03\x02\x02\x02\x05X\x03\x02\x02\x02\x07a\x03\x02" +
		"\x02\x02\tk\x03\x02\x02\x02\vs\x03\x02\x02\x02\r|\x03\x02\x02\x02\x0F" +
		"\x89\x03\x02\x02\x02\x11\x8E\x03\x02\x02\x02\x13\x94\x03\x02\x02\x02\x15" +
		"\xA2\x03\x02\x02\x02\x17\xAD\x03\x02\x02\x02\x19\xB8\x03\x02\x02\x02\x1B" +
		"\xC9\x03\x02\x02\x02\x1D\xDA\x03\x02\x02\x02\x1F\xE5\x03\x02\x02\x02!" +
		"\xE7\x03\x02\x02\x02#\xE9\x03\x02\x02\x02%\xEB\x03\x02\x02\x02\'\xED\x03" +
		"\x02\x02\x02)\xEF\x03\x02\x02\x02+\xF1\x03\x02\x02\x02-\xF3\x03\x02\x02" +
		"\x02/\xF5\x03\x02\x02\x021\xF7\x03\x02\x02\x023\xF9\x03\x02\x02\x025\xFB" +
		"\x03\x02\x02\x027\xFD\x03\x02\x02\x029\xFF\x03\x02\x02\x02;\u0101\x03" +
		"\x02\x02\x02=\u0103\x03\x02\x02\x02?\u0105\x03\x02\x02\x02A\u0107\x03" +
		"\x02\x02\x02C\u0109\x03\x02\x02\x02E\u010B\x03\x02\x02\x02G\u010D\x03" +
		"\x02\x02\x02I\u010F\x03\x02\x02\x02K\u0111\x03\x02\x02\x02M\u0113\x03" +
		"\x02\x02\x02OP\x07c\x02\x02PQ\x07d\x02\x02QR\x07u\x02\x02RS\x07v\x02\x02" +
		"ST\x07t\x02\x02TU\x07c\x02\x02UV\x07e\x02\x02VW\x07v\x02\x02W\x04\x03" +
		"\x02\x02\x02XY\x07e\x02\x02YZ\x07q\x02\x02Z[\x07p\x02\x02[\\\x07v\x02" +
		"\x02\\]\x07t\x02\x02]^\x07c\x02\x02^_\x07e\x02\x02_`\x07v\x02\x02`\x06" +
		"\x03\x02\x02\x02ab\x07k\x02\x02bc\x07p\x02\x02cd\x07v\x02\x02de\x07g\x02" +
		"\x02ef\x07t\x02\x02fg\x07h\x02\x02gh\x07c\x02\x02hi\x07e\x02\x02ij\x07" +
		"g\x02\x02j\b\x03\x02\x02\x02kl\x07n\x02\x02lm\x07k\x02\x02mn\x07d\x02" +
		"\x02no\x07t\x02\x02op\x07c\x02\x02pq\x07t\x02\x02qr\x07{\x02\x02r\n\x03" +
		"\x02\x02\x02st\x07h\x02\x02tu\x07w\x02\x02uv\x07p\x02\x02vw\x07e\x02\x02" +
		"wx\x07v\x02\x02xy\x07k\x02\x02yz\x07q\x02\x02z{\x07p\x02\x02{\f\x03\x02" +
		"\x02\x02|}\x07e\x02\x02}~\x07q\x02\x02~\x7F\x07p\x02\x02\x7F\x80\x07u" +
		"\x02\x02\x80\x81\x07v\x02\x02\x81\x82\x07t\x02\x02\x82\x83\x07w\x02\x02" +
		"\x83\x84\x07e\x02\x02\x84\x85\x07v\x02\x02\x85\x86\x07q\x02\x02\x86\x87" +
		"\x07t\x02\x02\x87\x0E\x03\x02\x02\x02\x88\x8A\t\x02\x02\x02\x89\x88\x03" +
		"\x02\x02\x02\x8A\x8B\x03\x02\x02\x02\x8B\x89\x03\x02\x02\x02\x8B\x8C\x03" +
		"\x02\x02\x02\x8C\x10\x03\x02\x02\x02\x8D\x8F\t\x03\x02\x02\x8E\x8D\x03" +
		"\x02\x02\x02\x8F\x90\x03\x02\x02\x02\x90\x8E\x03\x02\x02\x02\x90\x91\x03" +
		"\x02\x02\x02\x91\x92\x03\x02\x02\x02\x92\x93\b\t\x02\x02\x93\x12\x03\x02" +
		"\x02\x02\x94\x95\x071\x02\x02\x95\x96\x07,\x02\x02\x96\x9A\x03\x02\x02" +
		"\x02\x97\x99\v\x02\x02\x02\x98\x97\x03\x02\x02\x02\x99\x9C\x03\x02\x02" +
		"\x02\x9A\x9B\x03\x02\x02\x02\x9A\x98\x03\x02\x02\x02\x9B\x9D\x03\x02\x02" +
		"\x02\x9C\x9A\x03\x02\x02\x02\x9D\x9E\x07,\x02\x02\x9E\x9F\x071\x02\x02" +
		"\x9F\xA0\x03\x02\x02\x02\xA0\xA1\b\n\x02\x02\xA1\x14\x03\x02\x02\x02\xA2" +
		"\xA6\x07$\x02\x02\xA3\xA5\v\x02\x02\x02\xA4\xA3\x03\x02\x02\x02\xA5\xA8" +
		"\x03\x02\x02\x02\xA6\xA7\x03\x02\x02\x02\xA6\xA4\x03\x02\x02\x02\xA7\xA9" +
		"\x03\x02\x02\x02\xA8\xA6\x03\x02\x02\x02\xA9\xAA\x07$\x02\x02\xAA\xAB" +
		"\x03\x02\x02\x02\xAB\xAC\b\v\x02\x02\xAC\x16\x03\x02\x02\x02\xAD\xB1\x07" +
		")\x02\x02\xAE\xB0\v\x02\x02\x02\xAF\xAE\x03\x02\x02\x02\xB0\xB3\x03\x02" +
		"\x02\x02\xB1\xB2\x03\x02\x02\x02\xB1\xAF\x03\x02\x02\x02\xB2\xB4\x03\x02" +
		"\x02\x02\xB3\xB1\x03\x02\x02\x02\xB4\xB5\x07)\x02\x02\xB5\xB6\x03\x02" +
		"\x02\x02\xB6\xB7\b\f\x02\x02\xB7\x18\x03\x02\x02\x02\xB8\xB9\x07r\x02" +
		"\x02\xB9\xBA\x07t\x02\x02\xBA\xBB\x07c\x02\x02\xBB\xBC\x07i\x02\x02\xBC" +
		"\xBD\x07o\x02\x02\xBD\xBE\x07c\x02\x02\xBE\xC2\x03\x02\x02\x02\xBF\xC1" +
		"\v\x02\x02\x02\xC0\xBF\x03\x02\x02\x02\xC1\xC4\x03\x02\x02\x02\xC2\xC3" +
		"\x03\x02\x02\x02\xC2\xC0\x03\x02\x02\x02\xC3\xC5\x03\x02\x02\x02\xC4\xC2" +
		"\x03\x02\x02\x02\xC5\xC6\x07=\x02\x02\xC6\xC7\x03\x02\x02\x02\xC7\xC8" +
		"\b\r\x02\x02\xC8\x1A\x03\x02\x02\x02\xC9\xCA\x07k\x02\x02\xCA\xCB\x07" +
		"o\x02\x02\xCB\xCC\x07r\x02\x02\xCC\xCD\x07q\x02\x02\xCD\xCE\x07t\x02\x02" +
		"\xCE\xCF\x07v\x02\x02\xCF\xD3\x03\x02\x02\x02\xD0\xD2\v\x02\x02\x02\xD1" +
		"\xD0\x03\x02\x02\x02\xD2\xD5\x03\x02\x02\x02\xD3\xD4\x03\x02\x02\x02\xD3" +
		"\xD1\x03\x02\x02\x02\xD4\xD6\x03\x02\x02\x02\xD5\xD3\x03\x02\x02\x02\xD6" +
		"\xD7\x07=\x02\x02\xD7\xD8\x03\x02\x02\x02\xD8\xD9\b\x0E\x02\x02\xD9\x1C" +
		"\x03\x02\x02\x02\xDA\xDB\x071\x02\x02\xDB\xDC\x071\x02\x02\xDC\xE0\x03" +
		"\x02\x02\x02\xDD\xDF\n\x04\x02\x02\xDE\xDD\x03\x02\x02\x02\xDF\xE2\x03" +
		"\x02\x02\x02\xE0\xDE\x03\x02\x02\x02\xE0\xE1\x03\x02\x02\x02\xE1\xE3\x03" +
		"\x02\x02\x02\xE2\xE0\x03\x02\x02\x02\xE3\xE4\b\x0F\x02\x02\xE4\x1E\x03" +
		"\x02\x02\x02\xE5\xE6\x07(\x02\x02\xE6 \x03\x02\x02\x02\xE7\xE8\x07\x80" +
		"\x02\x02\xE8\"\x03\x02\x02\x02\xE9\xEA\x07~\x02\x02\xEA$\x03\x02\x02\x02" +
		"\xEB\xEC\x07>\x02\x02\xEC&\x03\x02\x02\x02\xED\xEE\x07@\x02\x02\xEE(\x03" +
		"\x02\x02\x02\xEF\xF0\x070\x02\x02\xF0*\x03\x02\x02\x02\xF1\xF2\x07?\x02" +
		"\x02\xF2,\x03\x02\x02\x02\xF3\xF4\x07*\x02\x02\xF4.\x03\x02\x02\x02\xF5" +
		"\xF6\x07\x7F\x02\x02\xF60\x03\x02\x02\x02\xF7\xF8\x07}\x02\x02\xF82\x03" +
		"\x02\x02\x02\xF9\xFA\x07+\x02\x02\xFA4\x03\x02\x02\x02\xFB\xFC\x07-\x02" +
		"\x02\xFC6\x03\x02\x02\x02\xFD\xFE\x07/\x02\x02\xFE8\x03\x02\x02\x02\xFF" +
		"\u0100\x07,\x02\x02\u0100:\x03\x02\x02\x02\u0101\u0102\x071\x02\x02\u0102" +
		"<\x03\x02\x02\x02\u0103\u0104\x07^\x02\x02\u0104>\x03\x02\x02\x02\u0105" +
		"\u0106\x07\'\x02\x02\u0106@\x03\x02\x02\x02\u0107\u0108\x07`\x02\x02\u0108" +
		"B\x03\x02\x02\x02\u0109\u010A\x07#\x02\x02\u010AD\x03\x02\x02\x02\u010B" +
		"\u010C\x07A\x02\x02\u010CF\x03\x02\x02\x02\u010D\u010E\x07<\x02\x02\u010E" +
		"H\x03\x02\x02\x02\u010F\u0110\x07=\x02\x02\u0110J\x03\x02\x02\x02\u0111" +
		"\u0112\x07.\x02\x02\u0112L\x03\x02\x02\x02\u0113\u0114\v\x02\x02\x02\u0114" +
		"\u0115\x03\x02\x02\x02\u0115\u0116\b\'\x02\x02\u0116N\x03\x02\x02\x02" +
		"\v\x02\x8B\x90\x9A\xA6\xB1\xC2\xD3\xE0\x03\b\x02\x02";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!SolidityBlocksLexer.__ATN) {
			SolidityBlocksLexer.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(SolidityBlocksLexer._serializedATN));
		}

		return SolidityBlocksLexer.__ATN;
	}

}

